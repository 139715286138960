
$(document).ready(function(){

    // ********** ELEMENTS EFFECT ON SCROL **********
    AOS.init({
        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 100, // offset (in px) from the original trigger point
        delay: 300, // values from 0 to 3000, with step 50ms
        duration: 400, // values from 0 to 3000, with step 50ms
        easing: 'ease-in-out-sine', // default easing for AOS animations
        once: false, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

    });


    // ********** SHOW BUTTON SCROLL UP **********
    // Active scroll up btn and hide language btn
    let btnActive = $('.scroll-btn--active').offset().top;
    let btnScroll = $('.scroll-button');
    let lagnBtn =  $('.language');


    $(window).on('scroll', function() {
        if ( $(window).scrollTop() > btnActive ) {
            btnScroll.addClass('scroll-button--show');
            lagnBtn.addClass('language--hide');
        }

        else {
            btnScroll.removeClass('scroll-button--show');  
            lagnBtn.removeClass('language--hide');      
        }
    });

    if ( $(window).scrollTop() > btnActive ) {
        btnScroll.addClass('scroll-button--show');
        lagnBtn.addClass('language--hide');
    }

    else {
        btnScroll.removeClass('scroll-button--show');  
        lagnBtn.removeClass('language--hide');      
    }

    // ********** BUTTON ON SCROLL UP **********
    // Scrolling up

    $('.scroll-button').on('click', function(e){
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0}, 600);
    });

    $('.scroll-down').on('click', function(e){
        e.preventDefault();
        let scrollUp = this.querySelector('a');
        $("html, body").animate({ scrollTop: $($.attr(scrollUp, 'href')).offset().top},
        600);
    });
  });

